import React from 'react';
import Link from 'gatsby-link';

const styles = {
  selected: {
    color: '#fff',
    cursor: 'default',
    fontWeight: 'bold',
  },
};

const matchEN = (location) => {
  if ((location === '/') | (location === '/en/privacy/'))
    return true;
  if (location.startsWith ('/en/nutrition-blog')) return true;
  return false;
};

const matchES = (location) => {
  if (
    (location === '/index_es/') |
    (location === '/es/privacidad/')
  )
    return true;
  if (location.startsWith ('/blog')) return true;
  return false;
};

const getLocation = (location) => {
	return location.startsWith("/en/nutrition-blog") ? "/en/nutrition-blog/" : "/#"
}

class Header extends React.Component {
  constructor (props) {
    super (props);
    this.state = {active: false};
  }

  onClick () {
    this.setState ({
      active: !this.state.active,
    });
  }

  setFalse () {
    this.setState ({
      active: false,
    });
  }

  render () {
    return (
      <div className="navbar-wrapper">
        <div className="navbar navbar-inverse navbar-fixed-top">
          <div className="navbar-inner">
            <div className="container">
              <div
              tabIndex={-1}
                className="btn btn-navbar"
                data-toggle="collapse"
                data-target=".nav-collapse"
                onClick={this.onClick.bind (this)}
              >
                {' '}
                <span className="icon-bar" /> <span className="icon-bar" />{' '}
                <span className="icon-bar" />{' '}
              </div>
              <h4 className="brand">
                <Link
                  innerRef={el => {
                    this.myLink = el;
                  }}
                  to={getLocation(this.props.location)}
                >
                  Nutritionist in Pennsylvania
                </Link>
              </h4>
              <nav
                style={this.state.active ? {height: 'auto'} : {height: '0px'}}
                className={
                  this.state.active
                    ? ' nav-show pull-right nav-collapse in collapse'
                    : ' nav-hide pull-right nav-collapse collapse'
                }
              >
                <ul id="menu-main" className="nav">
                  <li>
                    <Link
                      title="Home"
                      to="/#"
                      onClick={this.setFalse.bind (this)}
                      innerRef={el => {
                        this.myLink = el;
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      title="blog"
                      to="/en/nutrition-blog/"
                      onClick={this.setFalse.bind (this)}
                      innerRef={el => {
                        this.myLink = el;
                      }}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      title="Consulting"
                      to="/#consulting"
                      onClick={this.setFalse.bind (this)}
                      innerRef={el => {
                        this.myLink = el;
                      }}
                    >
                      Consulting
                    </Link>
                  </li>
                  <li>
                    <Link
                      title="Philosophy"
                      to="/#philosophy"
                      onClick={this.setFalse.bind (this)}
                      innerRef={el => {
                        this.myLink = el;
                      }}
                    >
                      Philosophy
                    </Link>
                  </li>
                  <li>
                    <Link
                      title="About"
                      to="/#about"
                      onClick={this.setFalse.bind (this)}
                      innerRef={el => {
                        this.myLink = el;
                      }}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      title="contact"
                      to="/#contact"
                      onClick={this.setFalse.bind (this)}
                      innerRef={el => {
                        this.myLink = el;
                      }}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
                <ul id="lang-nav">
                  <li>
                    <Link
                      to={
                        this.props.location.startsWith ('/blog')
                          ? '/en/nutrition-blog/'
                          : '/'
                      }
                      title="English"
                      onClick={this.setFalse.bind (this)}
                      isActive={matchEN(this.props.location)}
                      activeStyle={styles.selected}
                      innerRef={el => {
                        this.myLink = el;
                      }}
                    >
                      EN
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={
                        this.props.location.startsWith ('/en/nutrition-blog')
                          ? '/blog/'
                          : '/index_es/'
                      }
                      title="Español"
                      onClick={this.setFalse.bind (this)}
                      isActive={matchES(this.props.location)}
                      activeStyle={styles.selected}
                      innerRef={el => {
                        this.myLink = el;
                      }}
                    >
                      ES
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
