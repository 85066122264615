import React from 'react';
//import Link from "gatsby-link";
import '../assets/scss/main.scss';

import Header from '../components/Header';
import Footer from '../components/Footer';

import HeaderEs from '../components/HeaderEs';
import FooterEs from '../components/FooterEs';

export default function Template({children, location}) {
  const locpath = location.pathname;
  return (
    <div>
      {(locpath === '/') |
        (locpath === '/privacy_en') |
        locpath.startsWith ('/en/nutrition-blog')
        ? <Header location={locpath} />
        : <HeaderEs location={locpath} />}
      {children}
      {(locpath === '/') |
        (locpath === '/privacy_en') |
        locpath.startsWith ('/en/nutrition-blog')
        ? <Footer location={locpath} />
        : <FooterEs location={locpath} />}
    </div>
  );
}
