import React from 'react'

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-wrapper">
        <div className="container">
          <footer>
            <div itemScope="" itemType="http://schema.org/LocalBusiness">
              <h3 className="footerh5">
                <span itemProp="name">Ilse Valle-Jones</span>
              </h3>
              <br />
              <span itemProp="description">
              Well-Being and Healthy Lifestyle{' '}
              </span>
              <span itemProp="url">www.ilsevalle.com</span>
              <div
                itemProp="address"
                itemScope=""
                itemType="http://schema.org/PostalAddress"
              >
                
                <br />
                <a href="/en/privacy/" style={{ color: '#691a00' }}>
                  Privacy Policy
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default Footer
