import React from 'react'

class FooterEs extends React.Component {
  render() {
    return (
      <div className="footer-wrapper">
        <div className="container">
          <footer>
            <div itemScope="" itemType="http://schema.org/LocalBusiness">
              <h3 className="footerh5">
                <span itemProp="name">Ilse Valle-Jones</span>
              </h3>
              <br />
              <span itemProp="description">
                Nutrióloga Psicoterapeuta&thinsp;
              </span>
              <span itemProp="url">www.ilsevalle.com</span>
              <div
                itemProp="address"
                itemScope=""
                itemType="http://schema.org/PostalAddress"
              >
                <span itemProp="addressLocality">
                  Collegeville, Zona de Philadelphia
                </span>
                ,&thinsp;
                <span itemProp="addressRegion">PA</span>,&thinsp;
                <span itemProp="addressCountry">USA</span>
                <br />
                <a href="/es/privacidad/" style={{ color: '#691a00' }}>
                  Política de Privacidad
                </a>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default FooterEs
