import React from 'react';
import Link from 'gatsby-link';

const styles = {
    selected: {
        color: "#fff",
        cursor: "default",
        fontWeight: "bold"
    }
};

const matchEN = (location) => {
    if (location === "/" | location === "/en/privacy/")
        return(true);
    if (location.startsWith("/en/nutrition-blog"))
        return(true);
    return(false);
};

const matchES = (location) => {
    if (location === "/index_es/" | location === "/es/privacidad/")
        return(true);
    if (location.startsWith("/blog"))
        return(true);
    return(false);
};

const getLocation = (location) => {
    return location.startsWith("/blog") ? "/blog/" : "/index_es/#"
}
class HeaderEs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { active: false };
    }

    onClick() {
        this.setState({
            active: !this.state.active
        });
    }

    setFalse() {
        this.setState({
            active: false
        });
     }

    render() {
        return (
            <div className="navbar-wrapper">
              <div className="navbar navbar-inverse navbar-fixed-top">
                <div className="navbar-inner">
                  <div className="container">
                    <div
                       className="btn btn-navbar"
                       data-toggle="collapse"
                       data-target=".nav-collapse"
                       onClick={this.onClick.bind(this)}
                       >
                      {' '}
                      <span className="icon-bar" /> <span className="icon-bar" />{' '}
                      <span className="icon-bar" />{' '}
                    </div>
                    <h4 className="brand">
                      <Link
                         innerRef={el => {
                             this.myLink = el;
                        }}
                        to={getLocation(this.props.location)}
                        >
                        Asesoría en Nutrición
                      </Link>
                    </h4>
                    <nav
                       style={this.state.active ? {height: "auto"}: {height: "0px"} }
                       className={this.state.active ? " nav-show pull-right nav-collapse in collapse": " nav-hide pull-right nav-collapse collapse"}
                       >
                      <ul id="menu-main" className="nav">
                        <li>
                          <Link
                             title="Inicio"
                             to="/index_es/#"
                             onClick={this.setFalse.bind(this)}
                             innerRef={el => {
                                 this.myLink = el;
                            }}
                            >
                            Inicio
                          </Link>
                        </li>
                        <li>
                          <Link
                             title="blog"
                             to="/blog/"
                             onClick={this.setFalse.bind(this)}
                             innerRef={el => {
                                 this.myLink = el;
                            }}
                            >
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link
                             title="Consulta"
                             to="/index_es/#consulting"
                             onClick={this.setFalse.bind(this)}
                             innerRef={el => {
                                 this.myLink = el;
                            }}
                            >
                            Consulta
                          </Link>
                        </li>
                        <li>
                          <Link
                             title="Filosofía"
                             to="/index_es/#philosophy"
                             onClick={this.setFalse.bind(this)}
                             innerRef={el => {
                                 this.myLink = el;
                            }}
                            >
                            Filosofía
                          </Link>
                        </li>
                        <li>
                          <Link
                             title="Acerca"
                             to="/index_es/#about"
                             onClick={this.setFalse.bind(this)}
                             innerRef={el => {
                                 this.myLink = el;
                            }}
                            >
                            Acerca
                          </Link>
                        </li>
                        <li>
                          <Link
                             title="Contacto"
                             to="/index_es/#contact"
                             onClick={this.setFalse.bind(this)}
                             innerRef={el => {
                                 this.myLink = el;
                            }}
                            >
                            Contacto
                          </Link>
                        </li>
                      </ul>
                      <ul id="lang-nav">
                        <li>
                          <Link
                             to={this.props.location.startsWith("/blog") ? "/en/nutrition-blog/" : "/" }
                             title="English"
                             onClick={this.setFalse.bind(this)}
                             isActive={matchEN(this.props.location)}
                             activeStyle={styles.selected}
                             innerRef={el => {
                                 this.myLink = el;
                            }}
                            >
                            EN
                          </Link>
                        </li>
                        <li>
                          <Link
                             to={this.props.location.startsWith("/en/nutrition-blog") ? "/blog/" : "/index_es/" }
                             title="Español"
                             onClick={this.setFalse.bind(this)}
                             isActive={matchES(this.props.location)}
                             activeStyle={styles.selected}
                             innerRef={el => {
                                 this.myLink = el;
                            }}
                            >
                            ES
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}

export default HeaderEs;
